<template>
  <div class="project-message pa-5" v-if="message">
    <div class="mb-4">
      {{ message.content }}
    </div>
    <div class="d-flex message-info">
      <span class="message-owner">{{ message.creatorName }}</span> -
      {{ formatedCreationDate }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectMessage",
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    formatedCreationDate() {
      let options = { year: "numeric", month: "long", day: "numeric" };
      let dateObj = new Date(this.message.creationDate);
      let userLang = navigator.language || navigator.userLanguage;
      return dateObj.toLocaleDateString(userLang, options);
    },
  },
};
</script>

<style>
.project-message {
  background-color: #f1f1f1;
  width: 60%;
  max-width: 50em;
  font-size: 10.5pt;
  line-height: 15pt;
  font-weight: normal;
  counter-reset: #333333;
}

.project-message .message-owner {
  color: #666666;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 8.25pt;
  line-height: 9.75pt;
}

.project-message .message-info {
  color: #666666;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 8.25pt;
  line-height: 9.75pt;
}
</style>
