<template>
  <div class="project-tracking" v-if="project && tenderRequest">
    <v-row>
      <v-col md="10" offset-md="1">
        <div class="text-md-h4 text-center mb-8 form-title tway-gray-dark--text">Seguimiento de Proyecto</div>
        <v-stepper class="elevation-0 mb-4" v-model="steps" alt-labels>
          <v-stepper-header class="pl-0 pr-0">
            <v-stepper-step
              :color="timeLine.shipDate ? 'tway-apple-green' : '#F57F17'"
              :complete="true"
              :complete-icon="timeLine.shipDate ? '$complete' : 'mdi-exclamation'"
              step=" "
            >
              <span
                v-if="
                  (timeLine.shipDate && !timeLine.cancelDate) ||
                  (timeLine.shipDate && timeLine.cancelDate && timeLine.revisionDate)
                "
                >Postulación Realizada
                <div class="last-update-label mt-2">
                  {{ formatISODate(timeLine.shipDate) }}
                </div>
              </span>
              <span v-if="!timeLine.shipDate && !timeLine.cancelDate"
                >Postulación Incompleta
                <div class="last-update-label mt-2" />
              </span>
              <span v-if="timeLine.cancelDate && !timeLine.revisionDate"
                >Postulación Cancelada
                <div class="last-update-label mt-2">
                  {{ formatISODate(timeLine.cancelDate) }}
                </div>
              </span>
            </v-stepper-step>
            <v-divider color="tway-apple-green" />
            <v-stepper-step
              step=" "
              :color="timeLine.cancelDate && !timeLine.selectionDate ? '#B71C1C' : 'tway-apple-green'"
              :complete="timeLine.revisionDate ? true : false"
              :complete-icon="timeLine.cancelDate && !timeLine.selectionDate ? 'mdi-close' : '$complete'"
            >
              <span v-if="!timeLine.cancelDate || (timeLine.cancelDate && timeLine.selectionDate)">
                Postulación Revisada
                <div class="last-update-label mt-2">
                  <span v-if="timeLine.revisionDate">{{ formatISODate(timeLine.revisionDate) }}</span>
                </div>
              </span>
              <span v-if="timeLine.cancelDate && !timeLine.selectionDate"
                >Postulación Cancelada
                <div class="last-update-label mt-2">
                  {{ formatISODate(timeLine.cancelDate) }}
                </div>
              </span>
            </v-stepper-step>
            <v-divider :color="'tway-apple-green'" />
            <v-stepper-step
              step=" "
              :color="
                (timeLine.selectionDate && !timeLine.tenderDate) || timeLine.cancelDate ? '#B71C1C' : 'tway-apple-green'
              "
              :complete="timeLine.selectionDate ? true : false"
              :complete-icon="
                (timeLine.selectionDate && !timeLine.tenderDate) || timeLine.cancelDate ? 'mdi-close' : '$complete'
              "
            >
              <span v-if="timeLine.selectionDate && !timeLine.cancelDate && !timeLine.tenderDate">
                Tu propuesta no fue seleccionada
                <div v-if="timeLine.selectionDate" class="last-update-label mt-2">
                  {{ formatISODate(timeLine.selectionDate) }}
                </div>
              </span>
              <span v-if="timeLine.cancelDate"
                >Postulación Cancelada
                <div class="last-update-label mt-2">
                  {{ formatISODate(timeLine.cancelDate) }}
                </div>
              </span>
              <span
                v-if="
                  (timeLine.selectionDate && !timeLine.cancelDate && timeLine.tenderDate) || !timeLine.selectionDate
                "
              >
                Tu propuesta ha sido seleccionada
                <div v-if="timeLine.selectionDate" class="last-update-label mt-2">
                  {{ formatISODate(timeLine.selectionDate) }}
                </div>
              </span>
            </v-stepper-step>
            <v-divider :color="'tway-apple-green'" />
            <v-stepper-step
              step=" "
              :color="timeLine.cancelDate ? '#B71C1C' : 'tway-apple-green'"
              :complete="timeLine.tenderDate ? true : false"
              :complete-icon="timeLine.cancelDate ? 'mdi-close' : '$complete'"
            >
              <span v-if="!timeLine.cancelDate">
                Proceso de Licitación
                <div v-if="timeLine.tenderDate" class="last-update-label mt-2">
                  {{ formatISODate(timeLine.tenderDate) }}
                </div>
              </span>
              <span v-if="timeLine.cancelDate"
                >Postulación Cancelada
                <div class="last-update-label mt-2">
                  {{ formatISODate(timeLine.cancelDate) }}
                </div>
              </span>
            </v-stepper-step>
            <v-divider :color="'tway-apple-green'" />
            <v-stepper-step
              step=" "
              :color="
                timeLine.cancelDate || (timeLine.finishDate && tenderRequest.status.id === 9 && !timeLine.cancelDate)
                  ? '#B71C1C'
                  : 'tway-apple-green'
              "
              :complete="timeLine.finishDate ? true : false"
              :complete-icon="
                timeLine.cancelDate || (timeLine.finishDate && tenderRequest.status.id === 9 && !timeLine.cancelDate)
                  ? 'mdi-close'
                  : '$complete'
              "
            >
              <span>
                Proceso de selección final exitoso/fallido
                <div v-if="timeLine.finishDate" class="last-update-label mt-2">
                  {{ formatISODate(timeLine.finishDate) }}
                </div>
              </span>
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>

        <v-alert
          color="tway-violet"
          dismissible
          outlined
          icon="mdi-information-outline"
          class="rounded-xl py-6 px-6 mb-8"
          close-icon=""
        >
          <div class="title font-weight-bold mb-2">Fechas de Procesos:</div>
          <p class="mt-1 mb-0">Proceso de Postulación: Finaliza el {{ applicationEndDateStr }}</p>
          <p class="mt-1 mb-0">Proceso de Licitación: Finaliza el {{ tenderEndDateStr }}</p>
          <p class="mt-1 mb-0">Fecha de resultados Licitación: Finaliza el {{ dateOfResultsStr }}.</p>
        </v-alert>

        <v-card height="fit-content" min-height="23.5em" class="px-0 py-0 mb-12">
          <v-row width="100%" justify="center" align="center" class="mb-6 ma-0 pa-0">
            <div class="tab-container tway-violet--text">
              <v-tabs background-color="tway-violet" dark center-active v-model="tab" align-with-title>
                <v-tab
                  v-if="!timeLine.cancelDate && timeLine.finishDate && tenderRequest.status.id !== 9 && canConfirm"
                  style="text-transform: initial"
                  class="tab-text px-10"
                >
                  Confirmación
                </v-tab>
                <v-tab style="text-transform: initial" class="tab-text px-10"> Proyecto </v-tab>
                <v-tab style="text-transform: initial" class="tab-text px-10"> Postulación </v-tab>
              </v-tabs>
            </div>
            <v-tabs-items style="width: 100% !important" v-model="tab">
              <v-tab-item
                v-if="
                  !(
                    timeLine.cancelDate ||
                    !timeLine.finishDate ||
                    (timeLine.finishDate && tenderRequest.status.id === 9 && !timeLine.cancelDate)
                  ) &&
                  project &&
                  project.awardedRequestid == null &&
                  canConfirm
                "
                class="px-5 py-5"
              >
                <div class="pb-3 pt-6" style="display: flex; justify-content: center; text-align: center">
                  <v-img :src="require('@/assets/img/confirmation.svg')" max-width="144px" height="101px" />
                </div>
                <div class="input-label pb-3 pt-6" style="display: flex; justify-content: center; text-align: center">
                  ¡Felicidades! Te han seleccionado para el proyecto
                </div>
                <div
                  class="text-profile px-4 roboto"
                  style="display: flex; justify-content: center; text-align: center"
                >
                  Te ha seleccionado para trabajar colaborativamente en su proyecto. Necesitamos que nos confirmes la
                  adjudicación de este proyecto para notificar a la empresa y empezar el trabajo colaborativo.
                </div>
                <div
                  class="roboto"
                  :style="desktopC ? 'display: flex; flex-direction: row;' : 'display: flex; flex-direction: column;'"
                  style="justify-content: center; text-align: center"
                >
                  <v-btn
                    class="text-uppercase text-body-1 px-7 py-5 roboto lower font-weight-bold mt-9"
                    rounded
                    :class="desktopC ? 'mr-3' : ''"
                    outlined
                    x-small
                    color="deep-purple accent-4"
                    @click="rechazar()"
                  >
                    rechazar ADJUDICACIÓN
                  </v-btn>
                  <v-btn
                    class="text-uppercase white--text text-body-1 px-7 py-5 roboto lower font-weight-bold mt-9"
                    rounded
                    x-small
                    color="deep-purple accent-4"
                    @click="aceptar()"
                  >
                    confirmar ADJUDICACIÓN
                  </v-btn>
                </div>
              </v-tab-item>
              <v-tab-item
                v-if="
                  !(
                    timeLine.cancelDate ||
                    !timeLine.finishDate ||
                    (timeLine.finishDate && tenderRequest.status.id === 9 && !timeLine.cancelDate)
                  ) &&
                  project &&
                  project.awardedRequestid != null &&
                  canConfirm
                "
                class="px-5 py-5"
              >
                <div class="pb-3 pt-6" style="display: flex; justify-content: center; text-align: center">
                  <v-img :src="require('@/assets/img/confirmation.svg')" max-width="144px" height="101px" />
                </div>
                <div class="input-label pb-3 pt-6" style="display: flex; justify-content: center; text-align: center">
                  Confirmación existosa
                </div>
                <div
                  class="text-profile px-4 roboto"
                  style="display: flex; justify-content: center; text-align: center"
                >
                  Ambas partes han aceptado trabajar en el proyecto. Además Tenemos la opción de acompañarte a través de
                  nuestro servicio de PMO Digital. Es un servicio exclusivo, automatizado y especializado para guiar el
                  proyecto de digitalización de tu empresa.
                </div>
                <div
                  class="roboto"
                  :style="desktopC ? 'display: flex; flex-direction: row;' : 'display: flex; flex-direction: column;'"
                  style="justify-content: center; text-align: center"
                >
                  <v-btn
                    class="text-uppercase white--text text-body-1 px-7 py-5 roboto lower font-weight-bold mt-9"
                    rounded
                    x-small
                    color="deep-purple accent-4"
                  >
                    activar PMO digital
                  </v-btn>
                </div>
              </v-tab-item>
              <v-tab-item class="px-5 py-5">
                <div class="input-label d-flex justify-space-between">Nombre del proyecto</div>
                <div class="text-profile roboto">
                  {{ project.name }}
                </div>
                <hr class="ml-n5 mr-n5 mt-3 mb-3 tab-divider" />
                <div class="input-label">Objetivos del negocio</div>
                <div class="text-profile roboto">
                  {{ project.businessGoal }}
                </div>
                <hr class="ml-n5 mr-n5 mt-3 mb-3 tab-divider" />
                <div class="input-label">Objetivos del proyecto</div>
                <div class="text-profile roboto">
                  {{ project.proyectGoal }}
                </div>
                <hr class="ml-n5 mr-n5 mt-3 mb-3 tab-divider" />
                <div class="input-label d-flex justify-space-between">Duración estimada</div>
                <div class="text-profile roboto">
                  {{ project.period.name }}
                </div>
                <div class="d-inline roboto mr-2">
                  <v-btn
                    class="text-uppercase text-body-1 roboto lower font-weight-bold mt-9"
                    rounded
                    text
                    x-small
                    color="deep-purple accent-4"
                    @click="goToProjectDetails()"
                  >
                    Ver detalle de proyecto
                  </v-btn>
                </div>
              </v-tab-item>
              <v-tab-item class="px-5 py-5">
                <div class="input-label d-flex justify-space-between">Propuesta de Valor</div>
                <div class="text-profile roboto">
                  {{ tenderRequest.valueProposal }}
                </div>
                <hr class="ml-n5 mr-n5 mt-3 mb-3 tab-divider" />
                <div class="input-label">Presupuesto</div>
                <div class="text-profile roboto">
                  {{ tenderRequest.budget }}
                </div>
                <hr class="ml-n5 mr-n5 mt-3 mb-3 tab-divider" />
                <div class="input-label">Duración del Proyecto</div>
                <div v-if="tenderRequest.period" class="text-profile roboto">
                  {{
                    ProjectPeriod(
                      tenderRequest.period.endDate.split("T")[0],
                      tenderRequest.period.startDate.split("T")[0]
                    )
                  }}
                </div>
                <div style="height: 5em" />
                <div class="d-block roboto mr-2 details-button">
                  <v-btn
                    class="text-uppercase text-body-1 roboto lower font-weight-bold mt-9"
                    rounded
                    text
                    x-small
                    color="deep-purple accent-4"
                    @click="goToTenderRequestDetails()"
                  >
                    Ver detalle de propuesta
                  </v-btn>
                </div>
              </v-tab-item>
              <v-tab-item class="chat-container">
                <div class="d-flex flex-column justify-end" style="min-height: 21em">
                  <div class="px-5 py-5" style="width: 100%">
                    <project-question
                      class="mb-4"
                      v-for="question in questions"
                      :key="question.id"
                      :question="question"
                    />
                    <div v-if="questions && questions.length === 0">
                      <div class="d-flex justify-center align-center mb-5">
                        <div style="width 100%;">
                          <v-img
                            :src="require('@/assets/img/empty_state_questions.svg')"
                            width="144px"
                            height="101px"
                          />
                        </div>
                      </div>
                      <div class="mx-md-16" style="text-align: center">
                        <div class="mb-1 empty-state-title">El Cliente no ha recibido preguntas</div>
                        <div class="mb-8 d-flex justify-center">
                          <span class="empty-state-text"
                            >Sé el primero en resolver tus dudas sobre el proyecto realizando preguntas claves sobre el
                            Proyecto. Puedes también observar las preguntas de los demás Especialistas que están
                            postulando.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="background-color: #f1f1f1; width: 100%" class="d-flex pa-3">
                    <input type="text" class="message-input" v-model="newQuestion" @keypress="onSendQuestionPress" />
                    <v-icon
                      class="ml-5"
                      color="tway-violet"
                      style="transform: rotate(-45deg)"
                      @click="createQuestion()"
                    >
                      mdi-send-outline
                    </v-icon>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item class="chat-container" v-if="chat">
                <div class="d-flex flex-column justify-end" style="min-height: 21em">
                  <div class="px-5 py-5" style="width: 100%">
                    <div
                      v-for="(msg, i) in chat.messages"
                      :key="i"
                      :class="[
                        'd-flex',
                        'mb-5',
                        msg.creatorId === tenderRequest.specialistId ? 'justify-end' : 'justify-start',
                      ]"
                    >
                      <project-message :message="msg" />
                    </div>
                  </div>
                  <div style="background-color: #f1f1f1; width: 100%" class="d-flex pa-3">
                    <input v-model="newMessage" type="text" class="message-input" @keypress="onSendMessagePress" />
                    <v-icon class="ml-5" color="tway-violet" style="transform: rotate(-45deg)" @click="createMessage()">
                      mdi-send-outline
                    </v-icon>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-row>
        </v-card>
        <div class="d-flex roboto mr-2 mb-16 justify-center">
          <v-btn
            class="text-uppercase text-body-1 roboto lower font-weight-bold mt-9"
            rounded
            text
            x-small
            color="deep-purple accent-4"
            @click="back()"
          >
            Volver
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="modal" max-width="500">
      <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 4px !important">
        <v-card-title
          class="headline mb-3"
          :class="!desktop ? 'pt-8' : ''"
          :style="!desktop ? 'font-size: 20px !important;' : ''"
        >
          <v-row class="ma-0 pa-0" justify="center">
            <v-icon color="#16C62E" size="75"> mdi-check-circle </v-icon>
          </v-row>
        </v-card-title>

        <v-card-text class="headline tway-gray-dark--text mb-4" style="font-size: 18px !important">
          <v-row class="ma-0 pa-0 text-center" justify="center">
            <span>Confirmación de adjudicación exitosa. Notificaremos a la empresa Cliente sobre tu decisión.</span>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-col v-if="desktop" class="text-center">
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="desktop"
              rounded
              color="tway-violet"
              class="elevation-2 mb-2 white--text roboto"
              @click="(modal = false), $router.go()"
            >
              ACEPTAR
            </v-btn>
          </v-col>
          <v-col v-if="mobile" class="text-center">
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="mobile"
              rounded
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              @click="(modal = false), $router.go()"
            >
              ACEPTAR
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProjectQuestion from "@/components/projects/ProjectQuestion.vue";
import ProjectMessage from "@/components/projects/ProjectMessage.vue";
import CallForTenderService from "@/services/callForTenderService";
import moment from "moment";

export default {
  name: "ProjectTracking",
  components: {
    ProjectQuestion,
    ProjectMessage,
  },
  data() {
    return {
      moment: moment,
      desktop: true,
      steps: 0,
      modal: false,
      tab: null,
      project: null,
      tenderRequest: null,
      timeLine: {},
      questions: null,
      loadingQuestions: false,
      loadingPreviousQuestions: false,
      messages: null,
      loadingMessages: false,
      loadingPreviousMessages: false,
      newQuestion: null,
      newMessage: null,
      chat: null,
    };
  },
  mounted() {
    let project = this.$route.params.project;
    let projectPathId = this.$route.params.projectId;
    if (project) {
      this.project = project;
      this.getTenderRequest(project.id);
    } else if (projectPathId) {
      this.getProject(projectPathId);
      this.getTenderRequest(projectPathId);
    } else {
      this.$router.push({ name: "my-projects" });
    }
  },
  computed: {
    applicationEndDateStr() {
      return this.project ? this.formatISODate(this.project.publicationPeriod.endDateISO) : "";
    },
    tenderEndDateStr() {
      return this.project ? this.formatISODate(this.project.biddingPeriod.endDateISO) : "";
    },
    tenderStartDateStr() {
      return this.project ? this.formatISODate(this.project.biddingPeriod.startDateISO) : "";
    },
    dateOfResultsStr() {
      return this.project ? this.formatISODate(this.project.dateOfResults) : "";
    },
    mobileC() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktopC() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    canConfirm() {
      let resultDay = this.project.dateOfResults;
      if (resultDay) {
        let date = new Date(resultDay);
        let today = new Date();
        let period = date - today;
        return period <= 0;
      }
      return false;
    },
  },
  methods: {
    aceptar() {
      CallForTenderService.acceptEndPhase(this.tenderRequest.id)
        .then(() => {
          this.modal = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rechazar() {},
    getTenderRequest(projectId) {
      CallForTenderService.getLastRequestByProject(projectId)
        .then((resp) => {
          this.tenderRequest = resp;
          this.timeLine = this.tenderRequest.timeLine;
          this.getQuestions();
        })
        .catch((err) => {
          this.$log.error("Unexpected error: ", err);
          this.$router.push({ name: "my-projects" });
        });
    },
    getQuestions() {
      CallForTenderService.getQuestions(this.tenderRequest.id)
        .then((resp) => (this.questions = resp))
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
    getProject(id) {
      CallForTenderService.getProject(id)
        .then((resp) => (this.project = resp))
        .catch((err) => {
          this.$log.error(err);
          this.$router.push({ name: "my-projects" });
        });
    },
    getMessages(requestId, chatId) {
      CallForTenderService.getMessages(requestId, chatId)
        .then((resp) => (this.chat = resp))
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
    onSendQuestionPress(event) {
      let code = event.keyCode ? event.keyCode : event.which;
      if (code == 13 && this.newQuestion) {
        this.createQuestion();
      }
    },
    onSendMessagePress(event) {
      let code = event.keyCode ? event.keyCode : event.which;
      if (code == 13 && this.newMessage) {
        this.createMessage();
      }
    },
    createQuestion() {
      let question = { questionText: this.newQuestion, ownerName: "test" };

      CallForTenderService.createQuestion(this.tenderRequest.id, question)
        .then((resp) => {
          this.questions.push(resp);
          this.newQuestion = null;
        })
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
    createMessage() {
      let message = { content: this.newMessage, creatorName: "test" };

      CallForTenderService.createMessage(this.tenderRequest.id, message)
        .then((resp) => {
          this.chat = resp;
          this.newMessage = null;
        })
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
    formatISODate(isoDate) {
      if (!isoDate) {
        return "";
      }
      let formatedDate = "";
      let date = new Date(isoDate);
      if (date) {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        formatedDate = `${day < 10 ? "0" : ""}${day}-${month < 10 ? "0" : ""}${month}-${year}`;
      }
      return formatedDate;
    },

    ProjectPeriod(endDate, startDate) {
      let end = moment(endDate);
      let start = moment(startDate);
      let PeriodDate = "";
      if (end && start) {
        let years = end.diff(start, "year");
        start.add(years, "years");
        let months = end.diff(start, "months");
        start.add(months, "months");
        let days = end.diff(start, "days");
        if (years > 0) {
          if (years > 1) {
            years = years + " Años ";
          } else {
            years = years + " Año ";
          }
        } else {
          years = "";
        }
        if (months > 0) {
          if (months > 1) {
            months = months + " Meses ";
          } else {
            months = months + " Mes ";
          }
        } else {
          months = "";
        }
        if (days > 0) {
          if (days > 1) {
            days = days + " Dias ";
          } else {
            days = days + " Dia";
          }
        } else {
          days = "";
        }
        PeriodDate = years + months + days;
      }
      return PeriodDate;
    },
    goToProjectDetails() {
      this.$router.push(`/project-description/${this.project.id}`);
    },
    goToTenderRequestDetails() {
      this.$router.push(`/apply-for-project/${this.tenderRequest.id}`);
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style>
.project-tracking .v-stepper .v-divider {
  background-color: white;
  border-top: dotted;
  /*color: chartreuse;*/
}

.project-tracking .v-stepper__step__step {
  height: 32px;
  min-width: 32px;
  width: 32px;
}

.project-tracking .v-stepper__label {
  text-align: center;
  font-size: 12pt;
}

.project-tracking .tab-container {
  width: 100%;
  border-top-style: solid;
  border-radius: 4px;
}

.project-tracking .v-tabs-bar__content {
  justify-content: space-around;
}

.project-tracking .text-profile {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #757575;
}

.project-tracking .terms-and-conditions >>> .v-label {
  color: rgba(0, 0, 0, 0.87) !important;
}

.project-tracking .tab-divider {
  margin-top: 5px;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: thin 0 0 0;
  border-style: solid;
}

/**  TODO: este estilo se podria dejar de usar, una alternativa es manejar el botón de detalles desde fuera del tab, cosa que su posición no se vea afectada por este**/
.project-tracking .details-button {
  display: block;
  height: 100%;
  position: absolute;
  top: calc(100% - 5.5em);
  bottom: 0px;
}

.project-tracking .chat-container {
  background-color: #fafafa;
}

.project-tracking .v-window-item {
  min-height: 21em;
}

.project-tracking .message-input {
  width: 100%;
  background-color: white;
  border-radius: 30px;
  line-height: 18pt;
  /* height: 44px; */
  font-family: Roboto Condensed;
  padding: 10px;
  font-size: 12pt;
  font-style: normal;
  font-weight: normal;
  color: #999999;
  outline: none;
}

.project-tracking .empty-state-title {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16.5pt;
  line-height: 22.5pt;
  text-align: center;
  color: #999999;
}

.project-tracking .empty-state-text {
  font-family: Roboto Condensed;
  font-size: 9.75pt;
  color: #999999;
  font-style: normal;
  font-weight: normal;
  line-height: 11.25pt;
  width: 40em;
}

.last-update-label {
  color: #999999;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 9.75pt;
  line-height: 11.25pt;
  text-align: center;
}
</style>
