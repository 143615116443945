<template>
  <v-card class="project-question pa-5" elevation="0">
    <div class="d-flex">
      <div class="input-label mr-md-10" style="width: 100%">
        {{ question.questionText }}

        <div class="d-flex justify-space-between text-profile roboto mt-4">
          <div class="d-flex">
            <v-icon small class="mr-2"> mdi-account-circle-outline </v-icon>
            <div class="user-text">
              Pregunta realizada por
              <span class="specialist-name"> {{ question.ownerName }} </span>
              (Publicado el 08/09/2020)
            </div>
          </div>
          <div class="d-flex text--tway-violet" v-if="question.answerText">
            <v-img class="mr-2" :src="require('@/assets/img/icons/carbon_chat_violet.svg')" />
            <div class="user-text mr-md-10">Cliente respondió</div>
          </div>
          <div class="d-flex" v-else>
            <v-img class="mr-2" :src="require('@/assets/img/icons/carbon_chat.svg')" />
            <div class="user-text mr-md-10">Cliente no ha respondido</div>
          </div>
        </div>
      </div>
      <v-btn icon @click="showAnswer = !showAnswer">
        <v-icon large>
          {{ showAnswer ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn>
    </div>
    <v-expand-transition>
      <div v-show="showAnswer" class="answer-text">
        <hr class="card-divider ml-n5 mr-n5 mt-5 mb-5" />
        <div class="mb-1">Respuesta:</div>
        <div>{{ question.answerText }}</div>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: "ProjectQuestion",
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showAnswer: false,
    };
  },
};
</script>

<style scoped>
.project-question {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
}

.project-question .user-text {
  font-size: 8.5pt;
}

.specialist-name {
  color: red;
}

.card-divider {
  margin-top: 5px;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: thin 0 0 0;
  border-style: solid;
}

.answer-text {
  color: #333333;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 9.75pt;
  line-height: 11.25pt;
}
</style>
